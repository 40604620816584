/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { MdDeleteForever } from 'react-icons/md';
import { TbCertificate } from 'react-icons/tb';
import Input from '@components/Input';
import RoundButton from '@components/RoundButton';
import { observer } from 'mobx-react-lite';
import { ICertificate } from '@interfaces/ISettings';

import { Container, CertificateView, CertificateData } from './styles';

interface IProps {
  certificate: ICertificate;
  onChange: (file: File, password: string) => void;
  onRemove: () => void;
}

interface IPropsNewCertificate {
  onChange: (file: File, password: string) => void;
}

const NewCertificate: React.FC<IPropsNewCertificate> = ({ onChange }) => {
  const [file, setFile] = useState(new File([], ''));
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (file || password) onChange(file, password);
  }, [file, password]);

  return (
    <Container>
      <label>
        Certificado
        <Input
          type="file"
          accept=".pfx"
          // value={file.name}
          onChange={(e) => {
            setFile(e.currentTarget.files[0]);
          }}
        />
      </label>
      <label>
        Senha do Certificado
        <Input
          type="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
      </label>
    </Container>
  );
};

const Certificate: React.FC<IProps> = observer(
  ({ certificate, onChange, onRemove }) => {
    return (
      <Container>
        {certificate?.serial ? (
          <>
            <label>Certificado</label>
            <CertificateView>
              <TbCertificate size={48} />
              <CertificateData>
                <p>
                  Serial: <strong>{certificate?.serial}</strong>
                </p>
                <p>
                  Assunto: <strong>{certificate?.subject}</strong>
                </p>
                <p>
                  Expiração:{' '}
                  <strong>{certificate?.expirationDate?.toString()}</strong>
                </p>
              </CertificateData>
              <RoundButton hint="Excluir" onClick={onRemove}>
                <MdDeleteForever size={24} />
              </RoundButton>
            </CertificateView>
          </>
        ) : (
          <NewCertificate onChange={onChange} />
        )}
      </Container>
    );
  }
);

export default Certificate;
